<template>
    <div>
        <div
            v-if="showFiltro"
            :class="`filtro-cuadro card shadow-lg ${
                showFiltro ? 'animate__animated animate__bounceInRight' : ''
            }`"
        >
            <b-row class="p-50 pb-0 mb-0">
                <b-col cols="5">Campo</b-col>
                <b-col>Valor</b-col>
            </b-row>
            <b-row class="px-50">
                <b-col cols="5" class="pr-0">
                    <v-select
                        :options="[
                            'Id',
                            'Nombre',
                            'Paciente',
                            'Profesional',
                            'fecha',
                        ]"
                        :clearable="false"
                    ></v-select>
                </b-col>
                <b-col class="pr-0">
                    <b-form-input></b-form-input>
                </b-col>
                <b-col cols="auto" class="pl-0 mt-25">
                    <b-button variant="flat-danger" pill class="py-50 px-50">
                        <feather-icon icon="XIcon"></feather-icon>
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center py-1">
                    <b-button variant="outline-info" pill class="p-50 mr-50">
                        <feather-icon icon="PlusIcon"></feather-icon>
                    </b-button>
                    <b-button variant="outline-success" pill class="py-50 mr-50"
                        >Filtrar</b-button
                    >
                    <b-button variant="outline-danger" pill class="py-50 px-50">
                        <feather-icon icon="RefreshCcwIcon"></feather-icon>
                    </b-button>
                </b-col>
            </b-row>
        </div>

        <b-button
            variant="dark"
            pill
            class="p-50 filtro"
            @click="showFiltro = !showFiltro"
        >
            <feather-icon icon="SearchIcon" size="20"></feather-icon>
        </b-button>

        <b-row class="mb-1 justify-content-center">
            <b-button
                :variant="
                    asistenciasSelected == 1
                        ? 'gradient-success'
                        : 'flat-success'
                "
                pill
                class="mx-2"
                @click="asistenciasSelected = 1"
                >Realizadas</b-button
            >
            <b-button
                :variant="
                    asistenciasSelected == 2 ? 'gradient-info' : 'flat-info'
                "
                pill
                class="mx-2"
                @click="asistenciasSelected = 2"
                >Reprogramadas</b-button
            >
            <b-button
                :variant="
                    asistenciasSelected == 3 ? 'gradient-danger' : 'flat-danger'
                "
                pill
                class="mx-2"
                @click="asistenciasSelected = 3"
                >Canceladas</b-button
            >
        </b-row>

        <div class="overflow-auto" style="height: calc(100vh - 40vh)">
            <div v-for="index in 5" :key="index" class="p-25">
                <b-card class="my-1 mx-2">
                    <b-row>
                        <b-col cols="auto">
                            <div>
                                <p class="mb-0 font-small-5 font-weight-bolder">
                                    Asistencia
                                </p>
                                <p class="mb-0 pb-50">No. {{ index }}</p>
                            </div>
                            <div class="text-left">
                                <p class="font-small-3 mb-0">20 ago. 2022</p>
                                <p class="font-small-2 mb-0 pb-75">03:40 pm</p>
                            </div>
                            <div>
                                <b-badge variant="success">Realizada</b-badge>
                            </div>
                        </b-col>
                        <b-col cols="auto">
                            <div>
                                <p class="pb-0 font-weight-bolder">
                                    Profesional
                                </p>
                                <p>Oscar Acelas</p>
                            </div>
                            <div>
                                <p class="pb-0 font-weight-bolder">Paciente</p>
                                <p class="mb-0">Gian Carlos Galán Sánchez</p>
                            </div>
                        </b-col>
                        <b-col>
                            <div>
                                <p class="font-weight-bolder">Dispositivos</p>

                                <div>
                                    <p class="text-muted">
                                        No se usaron dispositivos
                                    </p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    setup(props) {
        const asistenciasSelected = ref(1);
        const showFiltro = ref(false);

        return { asistenciasSelected, showFiltro };
    },
};
</script>
<style lang="css" scope>
.filtro {
    display: block;
    position: absolute;
    right: 30px;
    top: 100px;
    z-index: 20;
}

.filtro-cuadro {
    display: block;
    min-height: 120px;
    position: absolute;
    right: 100px;
    top: 120px;
    z-index: 20;
    width: 400px;
}
</style>
