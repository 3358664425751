var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showFiltro)?_c('div',{class:("filtro-cuadro card shadow-lg " + (_vm.showFiltro ? 'animate__animated animate__bounceInRight' : ''))},[_c('b-row',{staticClass:"p-50 pb-0 mb-0"},[_c('b-col',{attrs:{"cols":"5"}},[_vm._v("Campo")]),_c('b-col',[_vm._v("Valor")])],1),_c('b-row',{staticClass:"px-50"},[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"5"}},[_c('v-select',{attrs:{"options":[
                        'Id',
                        'Nombre',
                        'Paciente',
                        'Profesional',
                        'fecha' ],"clearable":false}})],1),_c('b-col',{staticClass:"pr-0"},[_c('b-form-input')],1),_c('b-col',{staticClass:"pl-0 mt-25",attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"py-50 px-50",attrs:{"variant":"flat-danger","pill":""}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center py-1"},[_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"outline-info","pill":""}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('b-button',{staticClass:"py-50 mr-50",attrs:{"variant":"outline-success","pill":""}},[_vm._v("Filtrar")]),_c('b-button',{staticClass:"py-50 px-50",attrs:{"variant":"outline-danger","pill":""}},[_c('feather-icon',{attrs:{"icon":"RefreshCcwIcon"}})],1)],1)],1)],1):_vm._e(),_c('b-button',{staticClass:"p-50 filtro",attrs:{"variant":"dark","pill":""},on:{"click":function($event){_vm.showFiltro = !_vm.showFiltro}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"20"}})],1),_c('b-row',{staticClass:"mb-1 justify-content-center"},[_c('b-button',{staticClass:"mx-2",attrs:{"variant":_vm.asistenciasSelected == 1
                    ? 'gradient-success'
                    : 'flat-success',"pill":""},on:{"click":function($event){_vm.asistenciasSelected = 1}}},[_vm._v("Realizadas")]),_c('b-button',{staticClass:"mx-2",attrs:{"variant":_vm.asistenciasSelected == 2 ? 'gradient-info' : 'flat-info',"pill":""},on:{"click":function($event){_vm.asistenciasSelected = 2}}},[_vm._v("Reprogramadas")]),_c('b-button',{staticClass:"mx-2",attrs:{"variant":_vm.asistenciasSelected == 3 ? 'gradient-danger' : 'flat-danger',"pill":""},on:{"click":function($event){_vm.asistenciasSelected = 3}}},[_vm._v("Canceladas")])],1),_c('div',{staticClass:"overflow-auto",staticStyle:{"height":"calc(100vh - 40vh)"}},_vm._l((5),function(index){return _c('div',{key:index,staticClass:"p-25"},[_c('b-card',{staticClass:"my-1 mx-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',[_c('p',{staticClass:"mb-0 font-small-5 font-weight-bolder"},[_vm._v(" Asistencia ")]),_c('p',{staticClass:"mb-0 pb-50"},[_vm._v("No. "+_vm._s(index))])]),_c('div',{staticClass:"text-left"},[_c('p',{staticClass:"font-small-3 mb-0"},[_vm._v("20 ago. 2022")]),_c('p',{staticClass:"font-small-2 mb-0 pb-75"},[_vm._v("03:40 pm")])]),_c('div',[_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Realizada")])],1)]),_c('b-col',{attrs:{"cols":"auto"}},[_c('div',[_c('p',{staticClass:"pb-0 font-weight-bolder"},[_vm._v(" Profesional ")]),_c('p',[_vm._v("Oscar Acelas")])]),_c('div',[_c('p',{staticClass:"pb-0 font-weight-bolder"},[_vm._v("Paciente")]),_c('p',{staticClass:"mb-0"},[_vm._v("Gian Carlos Galán Sánchez")])])]),_c('b-col',[_c('div',[_c('p',{staticClass:"font-weight-bolder"},[_vm._v("Dispositivos")]),_c('div',[_c('p',{staticClass:"text-muted"},[_vm._v(" No se usaron dispositivos ")])])])])],1)],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }